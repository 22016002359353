/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    --my-account-order-table-row-hover-background: var(--secondary-base-color);
}

[dir="ltr"] .MyAccountDocumentTableRow {
    height: 50px;

    &_disabled {
        opacity: 0.75;
        pointer-events: none;
    }

    td {
        font-weight: 400;
    }

    &-DownloadLink {
        display: block;
    }

    &-Document {
        display: flex;
        font-size: inherit;
        font-weight: inherit;

        svg {
            display: none;
        }

        &:hover {
            cursor: pointer;
            text-decoration: underline;
            
            svg {
                display: inline-block;
                margin-left: 10px;
            }
        }
    }

    &-Loader {
        margin-left: 10px;
    }

    &-Order {
        &_noOrderId {
            text-align: center;
        }
    }

    &-Status {
        text-transform: lowercase;
        text-align: center;
        padding: 0 10px;
        border-radius: 20px;
        background: $secondary-color-6;
    }

    &-Total {
        text-align: right;
    }
}
