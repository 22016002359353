/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountCustomerTable {
    &-ActionBar {
        @include mobile {
            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: 20px;
        }

        .Button {
            @include desktop {
                min-width: 200px;
            }

            @include mobile {
                margin: 0;
            }
        }
    }
}
