/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.MyAccountInformation {
    &-Wrapper {
        .Form {
            .FieldForm-Fields {
                @include mobile {
                    margin-bottom: 30px;
                }

                @include desktop {
                    column-gap: 50px;
                }
            }

            .FieldForm-Legend {
                border-bottom: 0;
                text-transform: lowercase;
                font-size: 18px;
                font-weight: 700;
                line-height: 140%;

                &::first-letter {
                    text-transform: uppercase;
                }

                @include mobile {
                    padding: 0;
                }
            }

            .FieldForm-Section {
                @include desktop {
                    width: 50%;
                }
            }

            .FieldForm-SectionWithSpace {
                @include mobile {
                    margin-block-start: 30px;
                }
            }
        }
    }

    &-Submit {
        @include desktop {
            min-width: 200px;
        }
    }
}
