/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountAddressBook {
    grid-row-gap: 5px;

    &-Button {
        @include desktop {
            top: -70px;
        }
    }
    
    &-BillingAddressInfo {
        background: $background-color-1;
        border-radius: 20px;
        color: $black;
        font-weight: 400;
        font-size: 12px;
        line-height: 140%;
        padding: 5px 10px;
        float: right;

        @include mobile {
            margin-top: 16px;
        }
    }

    .MyAccountAddressTable {
        &-ActionBar {
            @include mobile {
                margin: 10px 0 15px;
            }
        }

        .Button_underlinedLink {
            position: absolute;
            top: 51px;
            right: 0;
            z-index: 1;
        }

        .KeyValueTable {
            margin-bottom: 0;
        }

        .KeyValueTable-Heading {
            font-size: 16px;
            line-height: 110%;
            letter-spacing: 0.8px;
            text-transform: uppercase;
            padding-left: 0;
            font-weight: 700;

            div {
                margin: 20px 0 0;

                button {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    font-size: 18px;
                    line-height: 140%;
                    text-transform: initial;
                    font-weight: 400;

                    &:hover .RadioButtonIcon {
                        circle:nth-of-type(2) {
                            fill: var(--primary-base-color);
                            stroke: var(--primary-base-color);
                        }
                    }
                }

                .RadioButtonIcon {
                    display: block;
                    margin-right: 18px;
                }
            }
        }
    }
}
