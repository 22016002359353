/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

[dir="ltr"] .MyAccountMenuPage {
    margin-top: 50px;
    background: $secondary-color-5;

    &.ContentWrapper {
        @include mobile {
            padding: 15px $content-wrapper-padding-mobile;
            min-height: 100vh;
        }
    }

    .MyAccountTabListItem_isLogout {
        margin: 165px 0 80px;

        button {
            margin-top: 0;
        }
    }
}
