/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountOrderTableRow {
    td {
        font-weight: 400;
    }

    &-Status {
        text-transform: lowercase;
        text-align: center;
        padding: 0 10px;
        border-radius: 20px;
        background: $secondary-color-6;
    }
    
    
    .Tracking {
        z-index: 1;
        word-break: break-all;
        
        a {
            color: $black;
            font-size: 16px;
            font-weight: 400;
            text-decoration: underline;
            
            &:hover {
                color: $primary-color-1;
            }
        }
        
        &_empty {
            text-align: center;
        }
    }
}
