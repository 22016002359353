/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

[dir="ltr"] .MyAccountTabListItem {

    &:first-of-type button {
        border-top: 0;
    }

    &:last-of-type {
        margin: 0 14px;

        &:hover {
            &:after {
                display: none;
            }
        }
    }

    &-Button {
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        border-top: 2px solid $secondary-color-4;

        @include desktop {
            padding: 11px 0;
            min-width: 210px;
            margin: 0 14px;
        }

        svg {
            @include desktop {
                display: none;
            }
        }
    }

    .Button_isHollow {
        text-align: center;
        margin-top: 43px;
        margin-bottom: 10px;
        width: 100%;
    }

    &:active,
    &:hover,
    &_isActive {
        &::after {
            content: '';
            display: block;
            width: 2px;
            height: 22px;
            background-color: $primary-color-1;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            border-radius: 66px;
        }

        .MyAccountTabListItem-Button {
            font-weight: 700;
        }
    }

    &_isMyAccountMenuPage {
        .MyAccountTabListItem-Button {
            @include mobile {
                padding: 20.5px 0;
                font-weight: 400;
                font-size: 14px;
                line-height: 110%;
                letter-spacing: 0.05em;
                text-transform: uppercase;
            }
        }

        .ChevronIcon {
            width: 15px;
            height: 15px;

            path {
                stroke: $primary-color-1;
            }
        }
    }

}
