/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountDashboard {
    @include mobile {
        grid-row-gap: 28px;
        grid-column-gap: 0;
    }

    &-BlockTitle {
        border-bottom: 0;

        span {
            font-weight: 700;
            font-size: 18px;
            line-height: 140%;

            @include mobile {
                margin: 0 auto;
            }
        }

        &_isAddressBook {
            align-items: center;
            justify-content: space-between;

            .Button {
                min-width: unset;
                width: auto;
                margin-top: 0;
                text-align: center;
            }
        }
    }

    &-CustomerData {
        +div {
            grid-column: span 2;
        }
    }

    &-DefaultAddress {
        &:nth-child(2) {
            @include mobile {
                margin-block-start: 0;
            }
        }

        .KeyValueTable-Heading {
            text-transform: uppercase;
            padding-right: 0;


            @include mobile {
                padding-left: 0;
            }

            .Button {
                float: right;

                @include mobile {
                    display: table;
                    width: auto;
                    float: none;
                    margin-top: 16px;
                    margin-bottom: 16px;
                }
            }
        }

        >.Button {
            margin-bottom: 30px;
        }
    }

    &-BillingAddressInfo {
        background: $background-color-1;
        border-radius: 20px;
        color: $black;
        font-weight: 400;
        font-size: 12px;
        line-height: 140%;
        padding: 5px 10px;
        float: right;

        @include mobile {
            margin-top: 16px;
        }
    }

    &-AddressesWrapper {
        @include desktop {
            display: block;
        }
    }

    button+button {
        @include desktop {
            margin-inline-start: 20px;
            margin-top: 0;
        }
    }
}
