/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

[dir="ltr"] .MyAccountOrderItemsTable {
    &-Name {
        width: 40%;
    }

    &-Subtotal {
        width: 30%;
    }

    &-ProductSku {
        width: 20%;
    }

    &-Price {
        width: 25%;
    }

    &-ProductsWrapper {
        .MyAccountOrderItemsTable-Products {
            width: 100%;
            
            @include desktop {
                border-bottom: 2px solid $secondary-color-5;
                margin-bottom: 50px;
            }
    
            thead th,
            tbody td {
                font-size: 16px;
                font-weight: 400;
                line-height: 140%;
            }
    
            thead th {
                line-height: 110%;
                letter-spacing: 0.8px;
                padding: 22px 10px;
                text-transform: uppercase;
            }
    
            tbody td {
                line-height: 140%;
                padding: 24px 10px;
            }
    
            .MyAccountOrderItemsTableRow-RowWrapper td,
            tr th {
                &:nth-child(n+3) {
                    text-align: left;
                }
    
                &:nth-child(n+5) {
                    text-align: right;
                }
            }
            .ExpandableContent {
                &:first-of-type {
                    @include mobile {

                        border-top: 0;
                    }
                }
                &:last-of-type {
                    @include mobile {

                        border-bottom: 0;
                    }
                }
            }
            .ExpandableContent-Button {
                @include mobile {
                    padding: 20px 10px;
                }
            }
            .ExpandableContent-Heading {
                @include mobile {
                    font-size: 16px;
                }
            }
            .ExpandableContent-Content_isContentExpanded {
                .MyAccountOrderItemsTableRow-RowWrapper {
                    border-top: none;
                    td{
                    padding: 10px;
                    &::before {
                        @include mobile {
                        content: attr(data-th);
                        width: 50%;
                        font-weight: 400;
                        line-height: 140%;
                        font-size: 16px;
                        }
                    }
                    &:nth-child(1) {
                        display: none;
                    }
                    &:nth-child(5) {
                        text-align: left;
                        margin-bottom: 10px;
                    }
                }}
            }
        }
    }
}
