/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

[dir="ltr"] .MyAccountOverlay {
    &-Heading {
        font-family: $font-secondary;
        font-style: italic;
        font-weight: 300;
        font-size: 20px;
        line-height: 120%;
        letter-spacing: -0.03em;

        &:not(:empty) {
            margin-block-end: 47px;
        }

        @include mobile {
            padding: 0;
            font-size: 32px;
            margin-block-start: 16px;
        }
    }

    &_isVisible {
        &,
        &::before {
            @include desktop {
                border: 0;
                box-shadow: 0 0 20px rgba(128, 18, 47, 0.1);
            }
        }

        @include desktop {
            max-width: 450px;
        }
    }

    &-Buttons {
        margin-block-start: 0;
    }

    &-Action {
        @include desktop {
            padding-inline: 24px;
            padding-block: 33px 26px;
        }

        &.LoginAccount,
        &.ForgotPassword {
            &-InnerContainer {
                @include desktop {
                    max-width: 450px;
                    margin: 0 auto;
                }

                .MyAccountOverlay-Heading {
                    @include desktop {
                        font-size: 32px;
                    }
                }
            }
        }

        .Form {
            @include mobile {
                padding: 0;
            }
        }

        .Field {
            margin-block-start: 34px;
        }

        .Field-Wrapper {
            &:first-of-type .Field {
                margin-block-start: 0;
            }
        }

        input {
            width: 100%;
        }
    }


    &-Additional {
        @include mobile {
            padding: 20px 0 0;
        }

        section {
            display: flex;
            align-items: center;
            justify-content: space-between;

            +section {
                padding-block-start: 14px;
            }

            p {
                font-weight: 400;
                font-size: 14px;
                line-height: 140%;
                margin-block-end: 0;
            }

            a,
            .Button_likeLink {
                font-weight: 400;
                font-size: 14px;
                line-height: 140%;
                color: $primary-color-1;
                width: auto;
                margin: 0;
            }
        }

        .MyAccountOverlay-CreateAccountButton {
            justify-content: right;
        }
    }

    &-Legend {
        &_isConsents {
            .ShowMoreLessContent-Wrapper {
                flex-basis: 100%;
            }
        }
    }
}
