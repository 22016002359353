/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.MyAccountOrderTotals {
    &-Wrapper {
        padding: 20px 16px;
        background-color: $secondary-color-5;
        border-radius: 10px 0 0 0;

        @include desktop {

            width: 440px;
            margin-left: auto;
        }
    }

    &-Title {
        margin: 0 0 5px 0;
        padding-bottom: 10px;
        border-bottom: 1px solid $secondary-color-4;
    }

    &-Content {

        >div.MyAccountOrderTotals-FinalPrices>div,
        >div:not(.MyAccountOrderTotals-FinalPrices) {
            display: flex;

            >div {
                width: 50%;
                font-size: 16px;
                font-weight: 400;
                line-height: 140%;
                padding: 5px 0;
                color: $text-color-2;

                &:nth-of-type(2) {
                    text-align: right;
                }
            }
        }

        .MyAccountOrderTotals-Discount {
            >div:nth-child(2) {
                color: $primary-color-1;
            }
        }
    }

    &-FinalPrices {
        margin-top: 5px;
        border-top: 1px solid $secondary-color-4;
        padding-top: 5px;

        .MyAccountOrderTotals-GrandTotal {
            >div>div {
                color: $black;
            }

            &:nth-child(2)>div {
                font-size: 18px;

            }
        }
    }
}
