/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountOrder {
    &-AdditionalInfo {
        width: 100%;
        border-bottom: 2px solid $secondary-color-5;
        margin-bottom: 30px;

        @include mobile {
            border-color: $secondary-color-4;
        }

        thead th,
        tbody td {
            font-size: 16px;
            font-weight: 400;

            &:nth-of-type(4) {
                width: 180px;
            }
        }

        thead th {
            line-height: 110%;
            letter-spacing: 0.8px;
            padding: 22px 10px;
            text-transform: uppercase;
        }

        tbody td {
            line-height: 140%;
            padding: 13px 10px;
        }

        > div {
            display: flex;
            align-items: center;
            padding: 12px 0;

            &:first-of-type {
                padding: 0;
            }

            &:nth-of-type(3) span:first-child {
                text-decoration: underline;
            }

            span {
                width: 50%;
            }
        }

        .ShippingNumber {
            display: block;
            word-break: break-all;
            width: 50%;
            padding-right: 10px;

            a {
                color: $black;
                text-decoration: underline;
                font-size: 16px;
                font-weight: 400;

                &:hover {
                    color: $primary-color-1;
                }

                @include mobile {
                    display: block;
                }
            }
        }
    }

    [dir="ltr"] &-Reorder {
        color: $black;

        svg {
            margin-right: 10px;
        }

        span {
            border-bottom: 1px solid $black;
        }

        @include mobile {
            margin-left: 15px;
            margin-right: 0;

            svg {
                margin-right: 2px;
            }

            span {
                width: auto;
            }
        }
    }
}
