/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

[dir="ltr"] .MyAccount {
    &-Wrapper {
        grid-column-gap: 40px;
        padding-top: 0;
    }

    &-BackBtn {
        margin-bottom: 14px;
        position: relative;
        top: -20px;
        
        @include mobile {
            display: none;
        }
    }

    &-Heading {
        @include desktop {
            font-size: 32px;
            line-height: 120%;
            letter-spacing: -0.03em;
            margin-bottom: 30px;
        }
    }

    &-TabContent {
        @include desktop {
            padding: 20px 20px 110px;
            border: 0;
        }

        @include mobile {
            padding: 20px $content-wrapper-padding-mobile 110px;
        }
    }
}
