/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

[dir="ltr"] .MyAccountOverlay {
    &-PersonalInfoLegend,
    &-SignUpLegend,
    &-AttachmentLegend {
        @include paragraph-l();

        padding: 0;
    }

    &-ConsentLabel {
        margin: 0;

        .Field-Label_isRequired {
            font-size: 16px;
        }
    }

    &-RequiredInfo {
        @include paragraph-m();

        color: $text-color-2;
        margin: 35px 0 0 0;

        span {
            margin-right: 3px;
            color: $primary-color-1;
        }
    }

    &-SwitcherLabel {
        margin: 0;
    }

    &-Legend {
        margin-bottom: 50px;

        &_isNonPolishStore {
            input[name="taxvat"] {
                text-transform: uppercase;

                &::placeholder {
                    text-transform: initial;
                }
            }
        }

        &_isConsents {
            .Field-Wrapper {
                &:first-of-type .Field {
                    margin-top: 0;
                }

                &:not(:first-of-type) .Field {
                    margin-top: 50px;
                }

                &:not(:last-of-type) .Field-ErrorMessages {
                    margin-top: 26px;
                    margin-bottom: -26px;
                }
            }

            .Field-CheckboxLabel {
                flex-wrap: wrap;

                a {
                    margin-left: 3px;

                    @include paragraph-m();

                    z-index: 10;
                }
            }

            .ShowMoreLessContent-Wrapper {
                margin-top: 12px;
                padding-left: 29px;
            }

            .ShowMoreLessContent-Content {
                @include paragraph-m();

                color: $text-color-2;
            }

            .ShowMoreLessContent-Button {
                position: absolute;
                bottom: -30px;
                left: 29px;
            }
        }
    }

    &-AttachmentLegend {
        + p {
            margin: 14px 0 30px;

            @include paragraph-m();
        }

        .Field-Label_isRequired {
            position: relative;
            top: 0;
            left: 2px;
            font-size: 18px;
        }
    }

    &-SignUpButton {
        margin: 0;
    }
}
