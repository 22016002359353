/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

[dir="ltr"] .MyAccountOrderInformation {
    &-Wrapper {
        margin-block-start: 40px;

        @include mobile {
            margin-block-start: 20px;
        }
    }

    &-Information {
        @include desktop {
            display: flex;
            flex-wrap: nowrap;
            column-gap: 25px;
        }
    }

    &-Info,
    &-Comments {
        @include desktop {
            width: 100%;
        }
    }

    &-Comments,
    &-Column {
        border-radius: 10px 0 0 0;
        background: $secondary-color-3;
        padding: 20px 16px;
        margin: 0;
    }


    &-Info {
        display: flex;
        flex-direction: column;
        row-gap: 20px;

        @include desktop {
            width: 66%;
            row-gap: 25px;
        }

        >div {
            width: 100%;
            display: flex;
            row-gap: 20px;
            flex-direction: column;

            @include desktop {
                flex-direction: row;
                column-gap: 25px;
                row-gap: 25px;
            }
        }
    }

    &-Comments {
        min-height: 107px;

        @include desktop {
            width: 33%;
        }
    }

    &-Column {
        &_type_billingAddress,
        &_type_shippingAddress {
            .KeyValueTable th {
                display: none;
            }

            .KeyValueTable td {
                padding: 0;
            }

            table tbody tr:not(:last-child) {
                border: 0;
            }
        }
    }

    &-ColumnTitle {
        background-color: transparent;
        margin: 0 0 10px 0;
        padding: 0 0 10px 0;
        font-size: 18px;
        line-height: 140%;
        border-bottom: 1px solid $secondary-color-2;
    }

    &-Column .KeyValueTable td,
    &-PaymentMethod,
    &-ShippingMethod,
    &-CommentsText {
        font-weight: 400;
        line-height: 140%;
        font-size: 16px;
        color: $text-color-2;
    }

    &-CommentsText {
        word-wrap: break-word;

        &_noComments {
            display: flex;
            justify-content: center;
            margin-top: 10%;
            opacity: 0.75;
        }
    }

    &-PaymentMethod {
        @include mobile {
            padding-left: 0;
        }
    }
}
