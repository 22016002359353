/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CreateAccount {
    .ContentWrapper {
        padding-bottom: 110px;

        @include mobile {
            padding-inline: $content-wrapper-padding-mobile;
            padding-top: 50px;
        }
    }
    &-IntroText {
        &,
        p {
            @include paragraph-l();
            margin: 0;
        }
    }
    .Form {
        @include mobile {
            margin-top: 50px;
        }
    }
    &-Title {
        margin-bottom: 78px;

        @include mobile {
            display: none;
        }
    }

    &-InnerWrapper {
        @include desktop() {
            grid-gap: 20px;
        }

        >div,
        button {
            margin: 0;
        }
    }
}
